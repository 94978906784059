<template>
    <!--我的目标-->
    <div class="target">
        <!--添加目标开始-->
        <div class="target-now">
            <div class="target-name">
                当前目标:<span class="target-text" @click="showPopup = true">{{ bigTarget.tag && bigTarget.tag.name }}</span>
            </div>
            <div class="target-time">
                <div class="target-time-img">
                    <div class="target-time-bg"></div>
                    <img class="target-time-icon" src="~assets/images/user/alarm-clock.png"/>
                </div>
                <div class="target-time-item align-center">
                    <div class="vertical-center" @click="clickDateEndChange">
                        <input class="target-time-end" v-model=" bigTarget.end_at" placeholder="请选择目标时间" ref="targetTime"/>
                    </div>
                </div>
            </div>
            <div class="add-target">
                <van-field
                    v-model="addTarget"
                    rows="1"
                    autosize
                    type="textarea"
                    placeholder="+添加目标"
                    class="add-target-input" maxlength='200'
                    @blur="addTargets(bigTarget.tag.id,$event)" ref="fieldTarget"
                ></van-field>
            </div>
        </div>
        <!--添加目标结束-->
        <!--目标列表开始-->
        <div class="target-list">
            <van-tabs v-model:active="active" @click="onChange" class="custom-class" line-width="40" line-height="2">
                <div v-for="(tabItem,tabIndex) in tabBar" :key="tabIndex">
                    <van-tab :title="tabItem.type" :name="tabItem.id">
                        <van-list
                            v-model:loading="loading"
                            :finished="finished"
                            :immediate-check="false"
                            :finished-text="userTags.length>20 ? '没有更多了' :''"
                            @load="onLoad"
                        >
                            <NoData v-if="getUserTagsStatus && userTags && userTags.length === 0" :bottomBg="true"></NoData>
                            <div v-for="(item) in userTags" :key="item.id">
                                <van-swipe-cell  @open="openSwipe(item.id)"  @close="closeSwipe">
                                    <van-cell-group>
                                        <div class="list-con" :class="isCheck === item.id ? 'list-bg' : ''">
                                            <div class="list-item" @click="handleClick(item.id,item.status,$event)" >
                                                <div class="list-title-text list-space" :class="[item.status ? 'text-decoration' :'']">{{ item.content }}</div>
                                                <div class="overdue" v-if="new Date(item.end_at) < new Date() && item.status == 0">已过期</div>
                                                <div class="list-time">{{ item.end_at }}</div>
                                            </div>
                                        </div>
                                    </van-cell-group>
                                    <template #right>
                                        <div  class="list-item-right"  @click="delTargte(item.id)">删除</div>
                                    </template>
                                </van-swipe-cell>
                            </div>
                        </van-list>
                    </van-tab>
                </div>
            </van-tabs>
        </div>
        <!--目标列表结束-->
        <BottomTip v-show="userTags.target && userTags.target.length > 5"></BottomTip>

        <!--选择目标开始-->
        <div class="target-popup">
            <van-popup
                v-model:show="showPopup"
                position="top">
                <div class="popup-con">
                    <div class="popup-text popup-now">当前目标 {{ bigTarget.tag ? bigTarget.tag.name : '请选择目标' }}</div>
                    <div class="popup-item" v-for="item in target" :key="item.id">
                        <div class="popup-text " :class="item.id === bigTarget.tag.id ? 'active':''" @click="changeTag(item.id)">{{ item.name }}</div>
                    </div>
                </div>
            </van-popup>
        </div>
        <!--选择目标结束-->
        <!--选择时间-->
        <van-popup v-model:show="show" position="bottom" :style="{ height: '50%' }" >
            <van-datetime-picker v-if="showDatetimeEnd"
                 v-model="currentDateEnd"
                 type="date"
                 title="选择年月日"
                 :min-date="minDate"
                 @confirm="getEndTime"
                 @cancel="cancelTime"
            ></van-datetime-picker>
        </van-popup>
        <!--选择时间-->

        <van-toast id="van-toast" />
    </div>
</template>

<script>
// import { reactive } from "vue"
import BottomTip from "components/BottomTip";
import NoData from "components/NoData";
export default {
    name: "sign",
    data(){
        return {
            active: 2,
            userTags: [],        //用户目标列表
            target:[],           //目标列表
            start_at: '',        //结束时间
            end_at: '',          //开始时间
            addTarget: "",       //用户输入的目标信息
            searchTarget: "",     //用户搜索的目标信息
            showPopup: false,  //显示更改目标
            tabBar: [{
                type: '全部', id: 2
            },{
                type: '未完成', id: 0
            },{
                type: '已完成', id: 1
            }],
            isFocus: false,    //获取焦点
            showDatetimeStart: false, //显示开始时间面板
            showDatetimeEnd: false,//显示结束时间面板
            show: false,     //是否显示时间弹窗
            minDate: new Date(new Date().setDate(new Date().getDate()+1)),
            currentDateEnd: new Date(2020, 0, 1),
            delay: 700,
            clicks: 0,
            timer: null,
            isCheck: false,
            bigTarget:{},    //个人大目标
            loading: false,
            finished: false,
            page: 1,
            pageSize: 20,
            tag_id: 0,
            finishedText: '',
            getUserTagsStatus: false,
            isAddTarget: false
        }
    },
    created() {
        this.getBigTag()
        this.getTag()
    },
    methods:{
        // 关闭滑块
        closeSwipe(){
            this.isCheck = -1
        },
        // 打开滑块
        openSwipe(id){
            this.ids = id
            this.isCheck = id
        },
        // 双击点击事件
        handleClick(id,status,e) {
            let _this = this
            _this.clicks++;
            if (_this.clicks === 1) {
                _this.timer = setTimeout( () => {
                    _this.$router.push("/user/target/show/" + id)
                    _this.clicks = 0
                }, _this.delay);
            } else {
                clearTimeout(_this.timer);
                let url = _this.$api.Targets+'/' + id;
                let data ={
                    status: status ? 0 : 1
                }
                _this.$http.post(url,data,true).then( res => {
                    if(res.data.success){
                      _this.defaultFUn()
                      _this.getUserTags(_this.searchTarget, _this.active)
                        _this.clicks = 0;
                    }
                } )
            }

        },
        //获取用户小目标
        getUserTags(keywords,status){
            let _this = this
            let url = _this.$api.UserTags + '?page=' + _this.page + '&tag_id=' + _this.tag_id + '&pageSize=' + _this.pageSize
            _this.finished = true;
            if((status || status === 0) && keywords){
                url += "&keywords=" + keywords + "&status=" + status
            }else{
                if (status || status === 0) {
                    url += "&status=" + status
                } else if(keywords) {
                    url +="&keywords=" + keywords
                }
            }
            this.page ++;
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    this.loading = false;
                    if(res.data.data.data.length){
                        _this.finished = false;
                        const TODAY = _this.filterData(new Date()).split(' ')[0]
                        res.data.data.target = res.data.data.data.map(item => {
                            item.end_at = item.end_at.split(' ')[0]
                            if (item.end_at === TODAY){
                                item.end_at = "今天"
                            }
                            return item
                        })
                        _this.userTags = _this.userTags.concat(res.data.data.data)
                    }else {
                        this.finished = true;
                    }
                    _this.getUserTagsStatus = true
                }
            } )
        },
        //获取用户大目标
        getBigTag(){
            let _this = this
            let url = _this.$api.UserTags + '/big'
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    if (res.data.data.end_at){
                        res.data.data.end_at = res.data.data.end_at.split(' ')[0]
                    }
                    _this.bigTarget = res.data.data
                    _this.tag_id = res.data.data.tag.id;
                    _this.defaultFUn();
                    _this.getUserTags(this.searchTarget,this.status)
                }
            } )
        },
        // 获取目标
        getTag(){
            let _this = this
            _this.$http.get(_this.$api.Tags,true).then( res => {
                if(res.data.success){
                    _this.target = res.data.data.target
                }
            } )
        },
        // 添加目标
        addTargets(tagId){
            let _this = this
            if (_this.addTarget.trim().length == 0){
                return
            }
            if((0 < _this.addTarget.trim().length && _this.addTarget.trim().length < 2) || _this.addTarget.trim().length > 200){
              _this.$toast("请输入2-200字")
              return
            }

            if(_this.bigTarget.end_at === null){
              _this.$toast("请选择大目标时间")
              return
            }
            if(_this.isAddTarget) return
            _this.isAddTarget = true
            _this.page = 1
            let entAT = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
            let data = {
                tag_id: tagId,
                content: _this.addTarget,
                start_at: this.filterData(new Date()),
                end_at: this.filterData(entAT)
            }
            _this.isFocus = false
            _this.$http.post(_this.$api.Targets, data,true).then( res => {
                if(res.data.success){
                    _this.addTarget = ''
                    _this.isAddTarget = false
                    _this.$refs.fieldTarget.blur();
                    _this.defaultFUn();
                    _this.getUserTags(_this.searchTarget,_this.active)
                }
            } )
        },
        // 切换分类
        onChange(name){
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration:1000
          });
            this.active = name
            this.defaultFUn();
            this.getUserTags(this.searchTarget,name)
        },
        //删除小目标
        delTargte(id){
            let _this = this
            let url = _this.$api.Targets + "/" +id
            this.$dialog.confirm({
                message: '确定要删除该目标吗？',
                confirmButtonColor: '#ff6b29'
            }).then(() => {
                _this.$http.del(url,true).then( res => {
                    if(res.data.success){
                        _this.defaultFUn();
                        _this.getUserTags(this.searchTarget,this.status)
                    }
                } )
            })
        },
        //修改目标
        changeTag(id,bigid){
            let _this = this
            _this.defaultFUn()
            // _this.bigTarget = {}
            let url = _this.$api.UpdateTag + "/" + id

            _this.$http.post(url,{},true).then( res => {
                if(res.data.success){
                    _this.showPopup = false
                    _this.getBigTag()
                    _this.getUserTags(this.searchTarget,this.status)
                }
            } )
        },
        clickDateEndChange(){
            this.$refs.targetTime.blur();
            this.show = true
            this.showDatetimeEnd = true
        },
        // 修改目标时间
        getEndTime(value){
            this.page = 1
            let dataTime = new Date(new Date(new Date(value).toLocaleDateString()).getTime()+ 24 * 60 * 60 * 1000 - 1)
            this.end_at = dataTime.getFullYear() + '-' +(dataTime.getMonth()+1) + '-' + dataTime.getDate() + " " + dataTime.getHours() + ":" + dataTime.getMinutes() + ':' + dataTime.getSeconds()
            this.show = false
            let day = new Date();
            let data = {
                /*start_at: this.filterData(day),*/
                end_at: this.end_at
            }
            let url = this.$api.UpdateTagTime + '/' + this.bigTarget.tag.id
            this.$http.post(url,data,true).then( res => {
                if(res.data.success){
                  this.currentDateEnd = new Date();
                  this.defaultFUn()
                  this.getBigTag()
                }
            } )
        },
        // 取消时间
        cancelTime(){
            this.show = false
            this.currentDateEnd = new Date()
        },
        filterData(date){
            let month = 0
            let hours = 0
            let day = 0
            let minutes = 0
            let seconds = 0
            if ((date.getMonth()+1) < 10){
                month = '0'+(date.getMonth()+1)
            }else {
                month = date.getMonth()+1
            }


            if ((date.getHours()) < 10){
                hours = '0'+(date.getHours())
            }else {
                hours = date.getHours()
            }

            if ((date.getDate()) < 10){
                day = '0'+(date.getDate())
            }else {
                day = date.getDate()
            }


            if ((date.getMinutes()) < 10){
                minutes = '0'+(date.getMinutes())
            }else {
                minutes = date.getMinutes()
            }


            if ((date.getSeconds()) < 10){
                seconds = '0'+(date.getSeconds())
            }else {
                seconds = date.getSeconds()
            }
            return date.getFullYear()+'-'+ month +'-'+ day + " " + hours + ":" + minutes + ':' + seconds
        },
        // 取消
        cancelSearch(){
            this.searchTarget = ''
            this.getUserTags()
        },
        emptyInput(){
            this.searchTarget = ''
        },
        onLoad(){
            if(this.getUserTagsStatus){
              this.getUserTags(this.searchTarget,this.status)
            }
        },
        // 恢复默认值
        defaultFUn(){
          this.userTags = [];
          this.loading = false;
          this.finished= false;
          this.page = 1;
          this.getUserTagsStatus = false;
        }

    },
    components:{
        BottomTip,
        NoData
    }

}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/target/target.scss";
</style>
